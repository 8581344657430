import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { User } from "@/libs/schema/user";

import { CURRENT_USER } from "./reactQueryCacheKeys";

import { ERROR_CODES } from "@/libs/error/types";
import { makeApiUserCall } from "./user/useUser/api";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import useIsFeatureFlagEnabled from "@/src/services/optimizely/useIsFeatureFlagEnabled";
import { OptimizelyFeatureFlags } from "@/src/services/optimizely/optimizelyFeatureFlags";

export const useUser = (enabled = true) => {
  const { isLoading, error, data } = useQuery<
    Partial<User>,
    AxiosError,
    Partial<User>
  >([CURRENT_USER], makeApiUserCall, {
    retry: false,
    refetchOnMount: false,
    enabled: enabled,
    useErrorBoundary: ({ response }) =>
      response !== undefined && response?.status >= ERROR_CODES.INTERNAL_ERROR,
  });
  return { isLoading, error, user: data };
};

export type CurrentUser = Partial<User>;

/***
 *  We are trying to update current user to just use
 *  next auth session, but we are keeping the old one
 *  for now, until we are sure that the new one is working
 *  This feature flag will be removed in NVS-6252
 **/
export const useCurrentUser = () => {
  const { data, status } = useSession();
  const { enabled: optimizeUserCallsEnabled, clientReady } =
    useIsFeatureFlagEnabled(OptimizelyFeatureFlags.OPTIMIZE_USER_CALLS);
  const { currentUser, isLoading } = useOldCurrentUser(
    !!!optimizeUserCallsEnabled && clientReady,
  );
  return {
    currentUser: optimizeUserCallsEnabled
      ? data?.user ?? ({} as CurrentUser)
      : currentUser,
    isLoading:
      !clientReady ||
      (optimizeUserCallsEnabled && status === "loading") ||
      (!optimizeUserCallsEnabled && isLoading),
    status,
  };
};

export const useOldCurrentUser = (enabled = true) => {
  const { error, user, isLoading } = useUser(enabled);

  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>(
    undefined,
  );
  useEffect(() => {
    if (user && !error) {
      setCurrentUser(user);
    } else if (error) {
      setCurrentUser({}); // By the time we get here we will have handled other errors. This just handles if user does not exist. We will handle other errors that are not 400 in the useUser hook
    }
  }, [user, error]);
  return { currentUser, isLoading }; // isLoading and currentUser === undefined are the same thing, but both are exposed for clarity
};
