import pino from "pino";
import { DateString } from "@/libs/schema/api/user";
import { useMediaQuery } from "@react-hookz/web";
import {
  SCREEN_XL,
  SCREEN_SM,
  SCREEN_MD,
  SCREEN_LG,
  SCREEN_2XL,
  LAPTOP_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  XL_DESKTOP_BREAKPOINT,
} from "@/constants/app";

export const getBrightness = (n: number) => {
  const c = n; // strip #
  const rgb = c; // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma > 100;
};

export const getBrightnessRGB = (r: number, g: number, b: number) => {
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  console.log(luma);
  return luma > 150;
};

export const useIsPortrait = () => useMediaQuery("(orientation: portrait)");
export const useIsMinSm = () => useMediaQuery(`(min-width: ${SCREEN_SM})`);
export const useIsMinMd = () => useMediaQuery(`(min-width: ${SCREEN_MD})`);
export const useIsMinLg = () => useMediaQuery(`(min-width: ${SCREEN_LG})`);
export const useIsMinXl = () => useMediaQuery(`(min-width: ${SCREEN_XL})`);
export const useIsMin2Xl = () => useMediaQuery(`(min-width: ${SCREEN_2XL})`);

export const useIsMobile = () =>
  useMediaQuery(`(max-width: ${LAPTOP_BREAKPOINT}px)`);

export const useIsLaptopOrHigher = () =>
  useMediaQuery(`(min-width: ${LAPTOP_BREAKPOINT + 1}px)`);

export const useIsDesktopOrHigher = () =>
  useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT + 1}px)`);

export const useIsXLDesktopOrHigher = () =>
  useMediaQuery(`(min-width: ${XL_DESKTOP_BREAKPOINT + 1}px)`);

export const useIsTouchDevice = () =>
  useMediaQuery("(hover: none) and (pointer: coarse)");

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const isServer = () => typeof window === "undefined";

export const isClient = () => !isServer();

export const logger = pino({
  level: "debug", // TODO change for prod and make it configurable
  browser: {
    write(obj) {
      try {
        console.log(JSON.stringify(obj));
      } catch (err) {
        if (err instanceof Error) {
          // Without a `replacer` argument, stringify on Error results in `{}`
          console.log(JSON.stringify(err, ["name", "message", "stack"]));
        }

        console.log(JSON.stringify({ message: "Unknown error type" }));
      }
    },
  },
});

export const isValidDate = (date: Date): boolean => {
  return !isNaN(date.getTime());
};

export const normalizeDate = (date: Date | DateString) => {
  // date format MM.DD.YYYY
  if (!date) return "";
  const mmDdYyyy = new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formatedDate = mmDdYyyy.replaceAll("/", ".");

  return formatedDate;
};

export const normalizeTime = (date: Date | DateString | number) => {
  // time with timezone
  if (!date) return "";
  const timeDate = new Date(date);

  // Since new Date() doesn't throw but rather returns "Invalid Date" - if we get that, we never want to show it to users so just return --
  if (!isValidDate(timeDate)) return "--";

  const timeString = timeDate.toTimeString();
  const formattedTime = timeString.split(/[\+\-]/gm)[0]?.replace("GMT", "UTC");
  return formattedTime;
};

export const getRandomHexString = () => {
  // Generate a random number within the bounds and convert it to hexadecimal.
  // It is then padded with zeros so that it's always a valid six-digit hex code.
  const maxColorCombos = 16777215;
  const randomHexString = `#${Math.floor(Math.random() * maxColorCombos)
    .toString(16)
    .padStart(6, "0")}`;

  return randomHexString;
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const getUniqueArray = (array: any[], objectKeys?: string[]) => {
  const uniqueMap = new Map(
    array.map((item) => {
      const itemKeyValues =
        objectKeys && objectKeys.map((key) => item[key]).join(" ");
      const key = itemKeyValues ?? item;
      return [key, item];
    }),
  ).values();

  return Array.from(uniqueMap);
};
