export enum ERROR_CODES {
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  TOO_MANY_REQUESTS = 429,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  INTERNAL_ERROR = 500,
  ORDER_INTAKE_ERROR_CODE = 406,
}

// @TODO: get these from nvs-errors after the codes
// have been moved to types
export enum ORDER_INTAKE_ERROR_SUBCODES {
  ORDER_ALREADY_COMPLETED = 1036,
  UNAVAILABLE_PURCHASE = 1034,
  USER_PURCHASE_LIMIT_HIT = 1040,
  ORDER_NOT_FOUND = 1022,
}
export enum ERROR_400_SUBCODES {
  USER_DOES_NOT_EXIST = 1007,
  SALE_WINDOW_NOT_OPEN = 1006,
}

export enum UNAUTHORIZED_SUBCODES {
  REMFA_NEEDED = 1010,
}
